window.rsmWebsite = {
	elems: [],
	gtmTracking: if window.gtmTracking? then window.gtmTracking else false,
	ids: [],
	offers:	{
		list:	[],
		pageButton: false
	},
	overlay: {
		overlayDelay: undefined,
		oldScrollPos: 0
	}
}
app = window.rsmWebsite

# #########
# HELPERS
###########

# Check URL Parameters
app.CheckURLParams = ->
	url = window.location.href
	queryStart = url.indexOf("?") + 1
	queryEnd   = url.indexOf("#") + 1 || url.length + 1
	query = url.slice(queryStart, queryEnd - 1)
	pairs = query.replace(/\+/g, " ").split("&")
	parms = {}

	if (query == url || query == "") 
		return

	for i in [0..pairs.length-1]
		nv = pairs[i].split("=", 2)
		n = decodeURIComponent(nv[0])
		v = decodeURIComponent(nv[1])

		if (!parms.hasOwnProperty(n))
			parms[n] = []
		parms[n].push(if nv.length == 2 then v else null)

	return parms

# Cookies
app.GetCookie = (name) ->
	name = name + "="
	cookiearray = decodeURIComponent(document.cookie).split(';')
	for cookie in cookiearray
		while cookie.charAt(0) == ' '
			cookie = cookie.substring(1)
		if cookie.indexOf(name) == 0
			return cookie.substring(name.length, cookie.length)
	return ""
app.CreateCookie = (name, value, expdays, update) ->
	if (app.GetCookie(name) != "") && !update
		return false
	else
		if expdays != null || ''
			expdate = new Date()
			expdate.setTime(expdate.getTime() + (expdays*24*60*60*1000))
			document.cookie = name + "=" + value + ";expires="+ expdate.toUTCString() + ";path=/"
		else
			document.cookie = name + "=" + value + ";path=/"
	return true
app.DeleteCookie = (name) ->
	if app.GetCookie(name) == ""
		return false
	else
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
	return true

# Copy Text
app.CopyText = (text) ->
	copyDummy = document.createElement "textarea"
	document.body.appendChild copyDummy
	copyDummy.setAttribute "id", "copy-dummy_id"
	document.getElementById("copy-dummy_id").value = text
	copyDummy.select()
	document.execCommand "copy"
	document.body.removeChild copyDummy

	return

# Cumulative Offset
app.CumulativeOffset = (elem) ->
	top = 0
	left = 0
	if elem
		while true
			top += elem.offsetTop  || 0
			left += elem.offsetLeft || 0
			elem = elem.offsetParent

			break unless elem

	return {
		top: top,
		left: left
	}

# Element-In-Viewport
app.ElementInViewport = (elem, offsetTop, offsetBot, absolute) ->
	if elem
		offsetTop = if offsetTop then offsetTop else 0
		elemTop = if absolute then app.CumulativeOffset(elem).top else elem.offsetTop
		return elemTop + offsetTop <= window.pageYOffset + window.innerHeight && elemTop + elem.offsetHeight >= window.pageYOffset
	return false

# Has Class
app.HasClass = (elem, className) ->
	return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ')

# ID Maker
app.IdMaker = (el, prefix) ->
	# if id exists, return it...
	if el.id != ""
		return el.id
	# ...otherwise create id based by hash on actual time
	else
		return prefix + (+new Date).toString(36)

app.SetId = (el, prefix) ->
	id = app.IdMaker el, prefix
	while app.ids.indexOf(id) > -1
		id = app.IdMaker el, prefix
	app.ids.push id
	el.id = id
	return id

# Scroll To Element
app.ScrollToElem = (elem, offset, notSmooth, absolute) ->
	offset = if offset then offset else 0
	elemTop = if absolute then app.CumulativeOffset(elem).top else elem.offsetTop
	window.scrollTo {
		top: elemTop + offset, 
		left: 0,
		behavior: if notSmooth then "auto" else "smooth" 
	}
	return

# Trigger Event
app.TriggerEvent = (elem, eventName) ->
	elem.dispatchEvent new Event(eventName, {'bubbles': true})
	return

# #########
# COMMON
###########

# Anchor Link
app.AnchorLinkClickBinder = ->
	for anchorLink in document.querySelectorAll 'a[href^="#"]'
		if anchorLink.id.indexOf('elc-') < 0
			anchorLink.addEventListener 'click', (e) ->
				e.preventDefault()
				targetElem = document.querySelector this.getAttribute('href')
				if targetElem
					app.ScrollToElem targetElem, -window.innerHeight/4, false, true
				return
	return

# Hash URL
app.HashUrlBinder = ->
	if window.location.hash
		targetElem = document.querySelector window.location.hash
		if targetElem
			setTimeout ->
				app.ScrollToElem targetElem, -window.innerHeight/4, false, true
				return
			, 500
	return

# Error Outputs
app.ShowElementError = (element, errorText) ->
	formRow = element.closest '.form-row'
	if formRow
		formRow.classList.add 'error'
		formRow.querySelector('.form-row_error').innerHTML = errorText
	else
		element.classList.add 'error'
	return
app.HideElementError = (element) ->
	formRow = element.closest '.form-row'
	if formRow
		formRow.classList.remove 'error'
		formRow.querySelector('.form-row_error').innerHTML = ""
	else
		element.classList.remove 'error'
	return

# Overlay Opener
app.OverlayOpenerBinder = (onlyOverlay) ->
	if onlyOverlay
		overlayOpenerElemsList = document.getElementById('overlay').querySelectorAll '.overlay-opener'
	else
		overlayOpenerElemsList = document.querySelectorAll '.overlay-opener'
	for overlayOpenerElem in overlayOpenerElemsList
		overlayOpenerElem.addEventListener 'click', (e) ->
			e.preventDefault()
			app.GetContentAndOpenOverlay this.dataset.overlayId
			return
	return

# Window History Binder
app.WindowHistoryBinder = ->
	window.addEventListener 'popstate', (e) ->
		if e.state
			app.CheckForAutoOpenOverlay()
		return
	return

# #########
# ATOMS
# #########

# Checkbox
app.CheckboxBinder = (onlyOverlay) ->
	if onlyOverlay
		cbElemsList = document.getElementById('overlay').querySelectorAll '.checkbox'
	else
		cbElemsList = document.querySelectorAll '.checkbox'
	for cbElem in cbElemsList
		cbElem.querySelector('input[type=checkbox]').addEventListener 'change', (e) ->
			app.HideElementError this
			if this.hasAttribute 'required'
				if !this.checked
					app.ShowElementError this, 'Dieses Feld muss ausgewählt werden.'
			return
	return

# Iframe

app.IframeBinder = ->
	iframeElemsList = document.getElementById('overlay').querySelectorAll '.iframe'
	for iframeElem in iframeElemsList
		if app.HasClass iframeElem, 'internal'
			iframeElem.addEventListener 'load', (e) ->
				@style.height = @contentWindow.document.body.offsetHeight + 'px'
				return
	return

# Input
app.InputBinder = (onlyOverlay) ->
	if onlyOverlay
		inputElemsList = document.getElementById('overlay').querySelectorAll '.input'
	else
		inputElemsList = document.querySelectorAll '.input'
	for inputElem in inputElemsList
		if inputElem.querySelector('textarea')
			inputElem.querySelector('span.textarea').addEventListener 'input', (e) ->
				app.HideElementError this
				this.closest('.input').querySelector('textarea').value = this.innerText
				if this.innerText != ""
					this.classList.add 'filled'
				else
					if this.closest('.input').querySelector('textarea').hasAttribute 'required'
						app.ShowElementError this, "Dieses Feld muss ausgefüllt werden."
					this.classList.remove 'filled'
				return
		else
			inputElem.querySelector('input:not(input[type=hidden])').addEventListener 'input', (e) ->
				app.HideElementError this
				if this.type == "file"
					if this.value != ""
						
						if this.closest('.input').querySelector('input[type=hidden]')
							maxFileSize = parseInt(this.closest('.input').querySelector('input[type=hidden]').value,10)
						fileNames = []
						for file in this.files
							if maxFileSize
								if file.size <= maxFileSize
									fileNames.push file.name
								else
									this.value = ""
									app.ShowElementError this, "Die Datei konnte nicht hinzugefügt werden, da sie zu groß ist."
							else
								fileNames.push file.name
						this.closest('.input').querySelector('.input_input').innerHTML = fileNames.join(', ')
					else
						this.closest('.input').querySelector('.input_input').innerHTML = ""
				if this.value != ""
					this.classList.add 'filled'
				else
					if this.hasAttribute 'required'
						if this.type != "file"
							app.ShowElementError this, "Dieses Feld muss ausgefüllt werden."
					this.classList.remove 'filled'
				return
		if inputElem.querySelector('.input_input')
			inputElem.querySelector('.input_input').addEventListener 'click', (e) ->
				this.closest('.input').querySelector('input[type=file]').click()
				return
	return

# Read-More-Button
app.ReadMoreButtonBinder = ->
	for rmbElem in document.querySelectorAll('.read-more-button')
		rmbElem.addEventListener 'click', (e) ->
			this.classList.toggle 'open'
			app.ScrollToElem this.closest('.read-more-wrap'), -window.innerHeight/4, true, true
			return
	return

# Select
app.SelectBinder = (onlyOverlay) ->
	if onlyOverlay
		selectElemsList = document.getElementById('overlay').querySelectorAll('.select')
	else
		selectElemsList = document.querySelectorAll('.select')
	if selectElemsList.length > 0
		document.addEventListener 'click', (e) ->
			for selectElem in document.querySelectorAll('.select.ready-to-close')
				selectElem.classList.remove 'open'
			return
	for selectElem in selectElemsList
		selectElem.addEventListener 'click', (e) ->
			this.classList.toggle 'open'
			return
		selectElem.addEventListener 'mouseleave', (e) ->
			this.classList.add 'ready-to-close'
			return
		selectElem.addEventListener 'mouseenter', (e) ->
			this.classList.remove 'ready-to-close'
			return
		if !app.HasClass(selectElem, 'no-input')
			for selectOption in selectElem.querySelectorAll('.select_content_option')
				selectOption.addEventListener 'click', (e) ->
					this.closest('.select').querySelector('.select_box_title').innerHTML = this.innerHTML
					this.closest('.select').querySelector('input[type=hidden]').value = this.dataset.option
					if app.HasClass selectElem, 'required'
						app.HideElementError selectElem
					return
	return


# #########
# MOLECULES
# #########

# Foldable Tile
app.FoldableTileBinder = (onlyOverlay) ->
	if onlyOverlay
		ftElemsList = document.getElementById('overlay').querySelectorAll('.foldable-tile')
	else
		ftElemsList = document.querySelectorAll '.foldable-tile'
	for ftElem in ftElemsList
		ftElem.addEventListener 'click', (e) ->
			if !app.HasClass(e.target, 'btn') && !app.HasClass(e.target.parentNode, 'btn')
				@.classList.toggle 'open'
				self = this
				setTimeout ->
					app.ScrollToElem self, -window.innerHeight/4, false, true
					return
				, 300
			return
	return

# Form Builder
app.FormBuilderBinder = (onlyOverlay) ->
	if onlyOverlay
		fbElemsList = document.getElementById('overlay').querySelectorAll('.form-builder')
	else
		fbElemsList = document.querySelectorAll '.form-builder'
	for fbElem in fbElemsList
		fbElem.addEventListener 'submit', (e) ->
			e.preventDefault()
			validForm = true
			for frElem in this.querySelectorAll '.form-row'
				for inputElem in frElem.querySelectorAll 'input'
					app.HideElementError inputElem
					if inputElem.hasAttribute 'required'
						if inputElem.type == "checkbox"
							if !inputElem.checked
								validForm = false
								app.ShowElementError inputElem, 'Dieses Feld muss ausgewählt werden.'
						else if inputElem.type == "file"
							if inputElem.value == ""
								validForm = false
								app.ShowElementError inputElem, "Es muss (mindestens) eine Datei hinzugefügt werden."
						else if inputElem.type != "hidden"
							if inputElem.value == ""
								validForm = false
								app.ShowElementError inputElem, "Dieses Feld muss ausgefüllt werden."
				for selectElem in frElem.querySelectorAll '.select'
					app.HideElementError selectElem
					if app.HasClass selectElem, 'required'
						if selectElem.querySelector('input[type=hidden]').value == ""
							validForm = false
							app.ShowElementError selectElem, "Hier muss eine Option gewählt werden."
				for textareaElem in frElem.querySelectorAll 'span.textarea'
					app.HideElementError textareaElem
					if textareaElem.closest('.input').querySelector('textarea').hasAttribute 'required'
						if textareaElem.innerText == ""
							validForm = false
							app.ShowElementError textareaElem, 'Dieses Feld muss ausgefüllt werden.'
			if validForm
				app.ShowFormBuilderProcessing this

				if window.google_tag_manager
					window.dataLayer.push {
						'event': 'formSubmit',
						'formName': @.dataset.name
					}

				if app.HasClass this, 'ajax-form'
					app.FormBuilderAjaxFormSubmit this
				else
					this.submit()
			else
				app.ShowFormMessage this, "Das Formular wurde nicht korrekt ausgefüllt.<br/>Bitte überprüfen Sie Ihre Eingaben.", 'error'
		
		fbElem.addEventListener 'reset', (e) ->
			for inputElem in this.querySelectorAll('input')
				inputElem.classList.remove 'filled'
				app.HideElementError inputElem
			for fakeInputElem in this.querySelectorAll('.input_input')
				fakeInputElem.innerText = ""
				app.HideElementError fakeInputElem
			for selectElem in this.querySelectorAll '.select'
				selectElem.querySelector('.select_box_title').innerHTML = selectElem.dataset.title
				selectElem.querySelector('input[type=hidden]').value = ""
				app.HideElementError selectElem
			for textareaElem in this.querySelectorAll 'span.textarea'
				textareaElem.innerText = ""
				textareaElem.closest('.input').querySelector('textarea').value = ""
				textareaElem.classList.remove 'filled'
				app.HideElementError textareaElem
			return

		fbElem.querySelector('.form-builder_content_message_container_button .btn').addEventListener 'click', (e) ->
			e.preventDefault()
			app.HideFormMessage this.closest('.form-builder')
			return

	return
app.ShowFormBuilderProcessing = (fbElem) ->
	if fbElem
		fbElem.classList.add 'processing'
	return
app.HideFormBuilderProcessing = (fbElem) ->
	if fbElem
		fbElem.classList.remove 'processing'
	return
app.ShowFormMessage = (fbElem, messageText, type) ->
	if fbElem
		fbElem.querySelector('.form-builder_content_message_container_text').innerHTML = messageText
		fbElem.classList.add 'message'
		if type == 'error'
			fbElem.classList.add 'form-error'
		fbElem.querySelector('.form-builder_content_message_container_text').scrollIntoView()
	return
app.HideFormMessage = (fbElem) ->
	if fbElem
		fbElem.querySelector('.form-builder_content_message_container_text').innerHTML = ""
		fbElem.classList.remove 'message'
		fbElem.classList.remove 'form-error'
	return
app.FormBuilderAjaxFormSubmit = (fbElem) ->
	if fbElem
		formData = new FormData(fbElem)

		ajaxFormRequest = new XMLHttpRequest()
		ajaxFormRequest.onreadystatechange = ->
			if ajaxFormRequest.readyState == 4
				if ajaxFormRequest.status == 200
					ajaxFormResponse = JSON.parse ajaxFormRequest.responseText
					app.HideFormBuilderProcessing fbElem
					if ajaxFormResponse.success
						app.ShowFormMessage fbElem, ajaxFormResponse.message
						fbElem.reset()
					else
						app.ShowFormMessage fbElem, ajaxFormResponse.message, 'error'
				else
					app.HideFormBuilderProcessing fbElem
					app.ShowFormMessage fbElem, "Beim Absenden des Formulars gab es leider ein Problem.", 'error'
		ajaxFormRequest.open "POST", fbElem.getAttribute('action')
		ajaxFormRequest.send(formData)
	return

# Headline-Copy
app.HeadlineCopyBinder = ->
	for hcElem in document.querySelectorAll('.headline-copy')
		app.HeadlineCopyUpdate hcElem
	return
app.HeadlineCopyUpdate = (hcElem) ->
	hlElem = hcElem.querySelector '.headline-copy_headline'
	hlSpanElem = hlElem.querySelector 'span'
	fontSize = parseInt window.getComputedStyle(hlElem).getPropertyValue('font-size'), 10
	fontSizeVW = (fontSize / window.innerWidth) * 100
	while hlSpanElem.offsetWidth > hlElem.offsetWidth && fontSizeVW > 1
		fontSizeVW = fontSizeVW - .1
		hlElem.style.fontSize = fontSizeVW + 'vw'
	while hlSpanElem.offsetHeight > (window.innerHeight - document.getElementById('header').offsetHeight) && fontSizeVW > 1
		fontSizeVW = fontSizeVW - .1
		hlElem.style.fontSize = fontSizeVW + 'vw'
	hlSpanElem.style.paddingTop = "1em"
	return

# Offer-Box
app.BuildOfferBox = (id, title, office, subline, disciplineId, locationId, isRealOffer) ->
	obElem = document.createElement 'a'
	obElem.classList.add 'offer-box'
	if !isRealOffer
		obElem.classList.add 'initiative'
	obElem.target = "_blank"
	obElem.tabIndex = "2"
	obElem.href = window.location.protocol + "//" + window.location.host + "?offer=" + id
	obElem.dataset.discipline = disciplineId
	obElem.dataset.location = locationId
	obElem.dataset.offerId = id
	obElem.dataset.office = office
	obElem.innerHTML = '<div class="offer-box_title">' + title + '</div><div class="offer-box_subline">' + subline + '</div>'
	obElem.querySelector('.offer-box_subline').innerHTML = obElem.querySelector('.offer-box_subline').innerText
	obElem.addEventListener 'click', (e) ->
		e.preventDefault()
		if window.google_tag_manager
			window.dataLayer.push {
				'event': 'offerClick',
				'offerId': @.dataset.offerId,
				'offerOffice': @.dataset.office
			}
		app.GetOfferDetailAndOpenOverlay this.dataset.offerId
		return
	return obElem

# Offer Info Box
app.OfferInfoBoxBinder = ->
	if app.gtmTracking
		for oibElem in document.querySelectorAll '.offer-info-box'
			oibElemButton = oibElem.querySelector '.offer-info-box_button .btn'
			if oibElemButton
				oibElemButton.addEventListener 'click', (e) ->
					if window.google_tag_manager
						oibElem = @.closest '.offer-info-box'
						window.dataLayer.push {
							'event': 'offerInfoBoxClick',
							'offerInfoBoxText': oibElem.querySelector('.offer-info-box_text').textContent,
							'offerInfoBoxTarget': if @.dataset.overlayId then (window.location.protocol + "//" + window.location.host + "/?overlay=" + @.dataset.overlayId) else @.href
						}
					return
	return

# Read-More-Wrap
app.ReadMoreWrapBinder = ->
	for rmwElem in document.querySelectorAll('.read-more-wrap')
		rmwElem.querySelector('.read-more-button').addEventListener 'click', (e) ->
			this.closest('.read-more-wrap').classList.toggle 'open'
			#app.ScrollToElem this.closest('.read-more-wrap'), 0, true
			return
	return

# Scroll-Down-Hint
app.ScrollDownHintBinder = ->
	for sdhElem in document.querySelectorAll('.scroll-down-hint')
		if app.HasClass sdhElem, 'clickable'
			sdhElem.addEventListener 'click', (e) ->
				e.preventDefault()
				window.scrollTo {
					top: window.scrollY + window.innerHeight, 
					left: 0,
					behavior: "smooth" 
				}
				return


# #########
# ORGANISMS
# #########

# Animated-Hero
app.AnimatedHeroBinder = ->
	ahElemsList = document.querySelectorAll('.animated-hero')
	if ahElemsList.length > 0
		document.addEventListener 'scroll', (e) ->
			for ahElem in document.querySelectorAll('.animated-hero')
				sdhElem = ahElem.querySelector('.scroll-down-hint')
				if app.ElementInViewport(ahElem)
					topPos = ahElem.offsetTop
					animationHeight = ahElem.querySelector('.animated-hero_animation-area').offsetHeight
					scrolledProportional = ((window.scrollY - topPos) / animationHeight)
					# make fixed parts visible
					ahElem.classList.remove 'hidden'

					# PLUS SYMBOL
					if app.HasClass ahElem, 'plus-symbol'
						# plus stroke animation
						ahElem.querySelector('.animated-hero_plus-left').style.overflow = "auto"
						ahElem.querySelector('.animated-hero_plus-right').style.overflow = "auto"
						strokeWidth = scrolledProportional * 20
						strokeWidth = if strokeWidth < .3 then .3 else if strokeWidth > 20 then 20 else strokeWidth
						ahElem.querySelector('.animated-hero_plus-left svg path').setAttribute 'stroke-width', strokeWidth + 'rem'
						ahElem.querySelector('.animated-hero_plus-right svg path').setAttribute 'stroke-width', strokeWidth + 'rem'
						ahElem.querySelector('.animated-hero_plus-left').style.overflow = "visible"
						ahElem.querySelector('.animated-hero_plus-right').style.overflow = "visible"

						#headline translate
						headlineTranslate = scrolledProportional * (window.innerWidth + window.innerHeight + ahElem.querySelector('.animated-hero_headline').offsetWidth)
						ahElem.querySelector('.animated-hero_headline').style.transform = "translateX(-" + headlineTranslate + "px)"

					# MILK BOX			
					if app.HasClass ahElem, 'milk-box'
						if scrolledProportional > .1
							ahElem.querySelector('.milkbox').classList.add 'show-faces'
						else
							ahElem.querySelector('.milkbox').classList.remove 'show-faces'
						if scrolledProportional > .4
							ahElem.querySelector('.animated-hero_milk-box_headline').classList.add 'show'
						else
							ahElem.querySelector('.animated-hero_milk-box_headline').classList.remove 'show'
						rotation = (scrolledProportional - .11) * 100
						rotation = if rotation < 0 then 0 else if rotation > 45 then 45 else rotation
						ahElem.querySelector('.milkbox').style.transform = 'rotateX(-16deg) rotateY(' + rotation + 'deg) rotateZ(0)'
						topX = (scrolledProportional - .56) * 700
						topX = if topX < 0 then 0 else if topX > 100 then 100 else topX
						ahElem.querySelector('.milkbox').style.top = '-' + topX + 'vh'

					if app.ElementInViewport(ahElem.querySelector('.animated-hero_content'))
						ahElem.classList.add 'finished'
					if ((window.scrollY - topPos) / (animationHeight - window.innerHeight)) > 1.05 || window.scrollY < topPos
						ahElem.classList.add 'hidden'
					if !app.ElementInViewport(ahElem.querySelector('.animated-hero_content'))
						ahElem.classList.remove 'finished'
					
					if sdhElem
						sdhElem.classList.remove 'hide'
				else
					ahElem.classList.add 'hidden'
					if sdhElem
						sdhElem.classList.add 'hide'

			return
		# initial scroll to prevent svg render errors
		window.scrollTo(0,50)
		window.scrollTo(0,0)
	return

# Header
app.HeaderBinder = ->
	header = document.getElementById('header')
	header.querySelector('#header_burgermenu').addEventListener 'click', (e) ->
		e.preventDefault()
		this.classList.toggle 'active'
		this.closest('#header').querySelector('#header_menu').classList.toggle 'open'
		return
	document.addEventListener 'scroll', (e) ->
		headerElem = document.getElementById('header')
		if window.scrollY >= 100
			headerElem.classList.add 'scrolled'
		else
			headerElem.classList.remove 'scrolled'
		return
	return

# Offers
app.OffersBinder = ->
	offersElemsList = document.querySelectorAll('.offers')
	if offersElemsList.length > 0
		app.LoadOffers()
	for offersElem in offersElemsList
		offersElem.querySelector('.offers_main_list').dataset.filterDisciplines = "all"
		offersElem.querySelector('.offers_main_list').dataset.filterLocations = offersElem.querySelector('.filter_tab.locations li.active').dataset.locationId
		offersElem.querySelector('.offers_main_list').dataset.titleSearch = "false"
		for filterTabSelectElem in offersElem.querySelectorAll('.offers_main_filter_tab-select button')
			filterTabSelectElem.addEventListener 'click', (e) ->
				this.closest('.offers_main_filter_tab-select').querySelector('button.active').classList.remove 'active'
				this.classList.add 'active'
				elemIndex = Array.prototype.slice.call(this.closest('.offers_main_filter_tab-select').children).indexOf(this)
				this.closest('.offers_main').querySelector('.filter_tab.active').classList.remove 'active'
				this.closest('.offers_main').querySelectorAll('.filter_tab')[elemIndex].classList.add 'active'
				return
		for filterTabElem in offersElem.querySelectorAll('.filter_tab li')
			filterTabElem.addEventListener 'click', (e) ->
				offersElem = this.closest('.offers')
				if app.HasClass this.closest('ul'), 'locations'
					offersElem.querySelector('.offers_main_list').dataset.filterLocations = @.dataset.locationId
					offersElem.querySelector('.offers_main_list').dataset.filterDisciplines = "all"
					offersElem.querySelector('.filter_tab.disciplines li.active').classList.remove 'active'
					offersElem.querySelector('.filter_tab.disciplines li[data-discipline-id="all"]').classList.add 'active'
				if app.HasClass this.closest('ul'), 'disciplines'
					offersElem.querySelector('.offers_main_list').dataset.filterLocations = "all"
					offersElem.querySelector('.offers_main_list').dataset.filterDisciplines = @.dataset.disciplineId
					offersElem.querySelector('.offers_main_list').dataset.titleSearch = @.dataset.titleSearch
					offersElem.querySelector('.filter_tab.locations li.active').classList.remove 'active'
					offersElem.querySelector('.filter_tab.locations li[data-location-id="all"]').classList.add 'active'
				if window.google_tag_manager
					window.dataLayer.push {
						'event': 'offersFilter',
						'filterName': @.textContent,
					}
				app.FilterOffers offersElem
				this.closest('.filter_tab').querySelector('li.active').classList.remove 'active'
				this.classList.add 'active'
				return
		offersElem.querySelector('.offers_main_list_show-all').addEventListener 'click', (e) ->
			app.FilterOffers this.closest('.offers'), true
			return

	return
app.AppendOffers = (offersElem) ->

	scriptElem = document.getElementById 'structured-job-positions'
	if !scriptElem
		scriptElem = document.createElement 'script'
		scriptElem.id = 'structured-job-positions'
		scriptElem.setAttribute 'type', 'application/ld+json'
		scriptElem.textContent = '[]'
		document.head.appendChild scriptElem
	scriptElemContent = JSON.parse scriptElem.textContent

	offersLocations = []
	for offersElemLocation in offersElem.querySelectorAll('.filter_tab.locations li')
		if offersElemLocation.dataset.location
			offersLocations.push {
				locationIds: offersElemLocation.dataset.locationId.split(",")
				location: offersElemLocation.dataset.location,
				zip: offersElemLocation.dataset.zip,
				street: offersElemLocation.dataset.street
				region: offersElemLocation.dataset.region,
				country: offersElemLocation.dataset.country
			}

	for offer in app.offers.list
		if !offer.isRealOffer
			initOfferElem = document.createElement 'a'
			initOfferElem.classList.add 'offers_main_list_initiative'
			initOfferElem.href = window.location.protocol + "//" + window.location.host + "?offer=" + offer.id
			initOfferElem.target = "_blank"
			initOfferElem.dataset.location = offer.locationId
			initOfferElem.dataset.offerId = offer.id
			initOfferElem.dataset.office = offer.office.substring(0,offer.office.indexOf(','))
			initOfferElem.innerHTML = offer.name.substring(0,offer.name.indexOf('.')) + " in " + offer.office.substring(0,offer.office.indexOf(',')) + "."
			initOfferElem.addEventListener 'click', (e) ->
				e.preventDefault()
				if window.google_tag_manager
					window.dataLayer.push {
						'event': 'initiativeClick',
						'offerId': @.dataset.offerId,
						'offerOffice': @.dataset.office
					}
				app.GetOfferDetailAndOpenOverlay this.dataset.offerId
				return
			offersElem.querySelector('.offers_main_list ul').parentNode.insertBefore initOfferElem, offersElem.querySelector('.offers_main_list ul').nextSibling
		else
			listElem = document.createElement 'li'
			obElem = app.BuildOfferBox offer.id, offer.name, offer.office.substring(0,offer.office.indexOf(',')), offer.description, offer.disciplineId, offer.locationId, offer.isRealOffer
			obElem.classList.add 'hide'
			listElem.append obElem
			offersElem.querySelector('.offers_main_list ul').append listElem
			 
			offerDate = new Date(offer.datePosted)
			datePosted = offerDate
			dateValid = new Date(offerDate.setMonth(offerDate.getMonth()+8))

			offerLocation = undefined
			for offerLocationEntry in offersLocations
				if offer.locationId.split(',').some((offerLocationId) -> offerLocationEntry.locationIds.includes(offerLocationId))
					offerLocation = offerLocationEntry
			if offerLocation
				offerStructuredData = {
					"@context" : "https://schema.org/",
					"@type" : "JobPosting",
					"title" : offer.name,
					"description" : "<p>" + offer.description + "</p>",
					"identifier": {
						"@type": "PropertyValue",
						"name": "Personio",
						"value": offer.id
					},
					"datePosted" : datePosted.getFullYear() + '-' + (if datePosted.getMonth() < 9 then '0' else '') + (datePosted.getMonth()+1) + '-' + datePosted.getDate(),
					"validThrough" : dateValid.getFullYear() + '-' + (if dateValid.getMonth() < 9 then '0' else '') + (dateValid.getMonth()+1) + '-' + datePosted.getDate() + "T00:00",
					"employmentType" : "CONTRACTOR",
					"hiringOrganization" : {
						"@type" : "Organization",
						"name" : offer.subcompany,
						"sameAs" : "http://ressourcenmangel.de",
						"logo" : "https://ressourcenmangel.de/files/ressourcenmangel/images/logo-ressourcenmangel.png"
					},
					"jobLocation": {
						"@type": "Place",
						"address": {
							"@type": "PostalAddress",
							"streetAddress": offerLocation.street,
							"addressLocality": offerLocation.location,
							"addressRegion": offerLocation.region,
							"postalCode": offerLocation.zip,
							"addressCountry": offerLocation.country
						}
					}
				}
			scriptElemContent.push offerStructuredData

	offersElem.querySelector('.offers_main_list_loading').classList.add 'hide'

	scriptElem.textContent = JSON.stringify scriptElemContent
	return
app.AppendOffersPageButton = (showOpenOffersCount) ->
	if !app.offers.pageButton
		opbElem = document.createElement 'button'
		opbElem.classList.add 'btn'
		opbElem.classList.add 'offers_button'
		opbElem.tabIndex = 1
		opbElem.setAttribute 'role', 'button'
		if showOpenOffersCount
			opbElem.innerHTML = '<span>' + (if app.offers.list.length > 0 then app.offers.list.length + (if app.offers.list.length > 1 then ' offenen Stellen' else ' offene Stelle') else 'Keine offene Stelle') + '</span>'
		else
			opbElem.innerHTML = '<span>Offene Stellen</span>'
		document.getElementById('content').appendChild opbElem
		opbElem.addEventListener 'click', (e) ->
			app.ScrollToElem document.querySelector('.offers'), -window.innerHeight/6, false, true
			return
		document.addEventListener 'scroll', (e) ->
			offersButton = document.querySelector '.offers_button'
			offsetTop = offersButton.innerHeight + parseInt(window.getComputedStyle(document.querySelector('.offers_button')).bottom, 10)
			for layerWhiteElem in document.querySelectorAll('.layer-white')
				if app.ElementInViewport layerWhiteElem, -offsetTop , 0, true
					offersButton.classList.remove 'orange'
					offersButton.classList.remove 'grey-dark'
					offersButton.classList.add 'white'
			for layerOrangeElem in document.querySelectorAll('.layer-orange')
				if app.ElementInViewport layerOrangeElem, -offsetTop, 0, true
					offersButton.classList.remove 'white'
					offersButton.classList.remove 'grey-dark'
					offersButton.classList.add 'orange'
			for layerGreyDarkElem in document.querySelectorAll('.layer-grey-dark')
				if app.ElementInViewport layerGreyDarkElem, -offsetTop, 0, true
					offersButton.classList.remove 'white'
					offersButton.classList.remove 'orange'
					offersButton.classList.add 'grey-dark'
			for layerHideElem in document.querySelectorAll('.layer-hide')
				if app.ElementInViewport layerHideElem, -offsetTop, 0, true
					offersButton.classList.add 'hide'
				else
					offersButton.classList.remove 'hide'

		app.offers.pageButton = true
	return
app.CreateOffers = ->
	for offersElem in document.querySelectorAll '.offers'
		if !app.offers.pageButton && offersElem.dataset.pageButton == "1"
			app.AppendOffersPageButton(offersElem.dataset.showOpenOffersCount)
		app.AppendOffers offersElem
		app.FilterOffers offersElem, false

	return
app.FilterOffers = (offersElem, wildCard) ->
	maxElems = if wildCard then 0 else parseInt offersElem.querySelector('.offers_main_list').dataset.maxResults, 10
	offersElem.querySelector('.offers_main_list_no-results').classList.remove 'show'
	if offersElem.querySelector('.offers_main_list_contact')
		offersElem.querySelector('.offers_main_list_contact').classList.remove 'show'
	offersElem.querySelector('.offers_main_list_show-all').classList.remove 'show'
	locationId = offersElem.querySelector('.offers_main_list').dataset.filterLocations
	disciplineId = offersElem.querySelector('.offers_main_list').dataset.filterDisciplines
	titleSearch = offersElem.querySelector('.offers_main_list').dataset.titleSearch
	count = 0
	allCount = 0
	for offerElem in offersElem.querySelectorAll('.offer-box')	
		offerElem.classList.add 'hide'
		#if ( locationId.split(',').includes(offerElem.dataset.location) || (locationId == "all") ) && ( (offerElem.dataset.discipline == disciplineId) || (disciplineId == "all") || (titleSearch == "true" && offerElem.querySelector('.offer-box_title').textContent.includes(disciplineId)) )
		if ( offerElem.dataset.location.split(',').some((offerLocationId) -> locationId.split(',').includes(offerLocationId)) || (locationId == "all") ) && ( (offerElem.dataset.discipline == disciplineId) || (disciplineId == "all") || (titleSearch == "true" && offerElem.querySelector('.offer-box_title').textContent.includes(disciplineId)) )
			allCount++
			if maxElems > 0
				if !app.HasClass(offerElem, 'initiative') && count < maxElems 
					offerElem.classList.remove 'hide'
					count++
			else
				offerElem.classList.remove 'hide'
				count++
	if count == 0
		offersElem.querySelector('.offers_main_list_no-results').classList.add 'show'
	else
		if !wildCard && count < allCount
			offersElem.querySelector('.offers_main_list_show-all').classList.add 'show'

	for initOfferElem in offersElem.querySelectorAll('.offers_main_list_initiative')
		if initOfferElem.dataset.location == locationId
			initOfferElem.classList.add 'show'
		else
			initOfferElem.classList.remove 'show'

	if offersElem.querySelector('.offers_main_list_contact')
		offersElem.querySelector('.offers_main_list_contact').classList.add 'show'
	return
app.LoadOffers = ->
	offersRequest = new XMLHttpRequest()
	offersRequest.onreadystatechange = ->
		if offersRequest.readyState == 4
			if offersRequest.status == 200
				app.offers.list = JSON.parse offersRequest.responseText
				app.CreateOffers()
	offersRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/offers", true
	offersRequest.send()
	return

# Overlay
app.OverlayBinder = ->
	overlay = document.getElementById('overlay')
	closeElem = document.getElementById('overlay_closer')
	closeElem.addEventListener 'click', (e) ->
		this.closest('#overlay').classList.remove 'dark'
		this.closest('#overlay').classList.remove 'bright'

		params = app.CheckURLParams()
		if params
			# delete overlay param
			if params.hasOwnProperty('overlay')
				url = new URL(window.location.href)
				url.searchParams.delete('overlay')
				window.history.pushState document.getElementById('site').innerHTML, document.title, url

			# delete offers param if overlay was offer overlay
			if params.hasOwnProperty('offer')
				url = new URL(window.location.href)
				url.searchParams.delete('offer')
				window.history.pushState document.getElementById('site').innerHTML, document.title, url

		# close overlay	
		document.getElementById('overlay').classList.remove 'open'
		window.clearInterval app.overlay.delay
		app.overlay.delay = window.setInterval ->
			window.clearInterval app.overlay.delay
			document.getElementById('overlay').classList.remove'show'
			document.getElementById('site').classList.remove 'overlay-open'
			document.getElementById('content').style.transform = ""
			window.scrollTo(0,app.overlay.oldScrollPos)
			return
		, 150
		return

	app.CheckForAutoOpenOverlay()

	return
app.OpenOverlay = (content, appendFunction) ->
	app.overlay.oldScrollPos = window.scrollY
	document.getElementById('site').classList.add 'overlay-open'
	document.getElementById('content').style.transform = "translateY(-" + app.overlay.oldScrollPos + "px)"
	overlay = document.getElementById('overlay')
	overlayContent = document.querySelector('#overlay_content .wrapper')
	overlayContent.innerHTML = content
	overlayColor = overlayContent.querySelector '.overlay_color'
	if overlayColor
		overlay.classList.add overlayColor.dataset.color
	overlay.classList.add 'show'
	window.clearInterval app.overlay.delay
	app.overlay.delay = window.setInterval ->
		window.clearInterval app.overlay.delay
		document.getElementById('overlay').classList.add 'open'

		# bind overlay content module functions
		app.OverlayOpenerBinder(true)
		app.CheckboxBinder(true)
		app.IframeBinder()
		app.InputBinder(true)
		app.SelectBinder(true)
		app.FoldableTileBinder(true)
		app.FormBuilderBinder(true)

		# reset scroll
		document.getElementById('overlay_content').scroll(0,0)

		# call appended function
		if appendFunction
			appendFunction()

		return
	, 150

	return
app.CheckForAutoOpenOverlay = ->
	params = app.CheckURLParams()
	
	if params
		# look for overlays to open at start
		if params.hasOwnProperty('overlay')
			if params.offer != ""
				app.GetContentAndOpenOverlay params.overlay

		# look for offer overlays to open at start
		if params.hasOwnProperty('offer')
			if params.offer != ""
				app.GetOfferDetailAndOpenOverlay params.offer
	return
app.GetContentAndOpenOverlay = (overlayId) ->
	app.ShowPageLoader()
	overlayContentRequest = new XMLHttpRequest()
	overlayContentRequest.onreadystatechange = ->
		if overlayContentRequest.readyState == 4
			if overlayContentRequest.status == 200
				if overlayContentRequest.responseText && overlayContentRequest.responseText != ""
					url = new URL(window.location.protocol + "//" + window.location.host)
					url.searchParams.append 'overlay', overlayId
					url.searchParams.set 'overlay', overlayId
					window.history.pushState document.getElementById('site').innerHTML, document.title, url
					app.OpenOverlay overlayContentRequest.responseText
				app.HidePageLoader()
			else
				app.HidePageLoader()
				console.log "Es ist ein Fehler aufgetreten. Fehler-Code: " + overlayContentRequest.status
	overlayContentRequest.open "GET", window.location.protocol + "//" + window.location.host + "/overlay/" + overlayId, true
	overlayContentRequest.send()
	return
app.GetOfferDetailAndOpenOverlay = (offerId) ->
	app.ShowPageLoader()
	offerDetailRequest = new XMLHttpRequest()
	offerDetailRequest.onreadystatechange = ->
		if offerDetailRequest.readyState == 4
			if offerDetailRequest.status == 200
				if offerDetailRequest.responseText && offerDetailRequest.responseText != ""
					url = new URL(window.location.protocol + "//" + window.location.host)
					url.searchParams.append 'offer', offerId
					url.searchParams.set 'offer', offerId
					window.history.pushState document.getElementById('site').innerHTML, document.title, url
					app.OpenOverlay offerDetailRequest.responseText, app.AppendOfferDataToOverlayForm
				app.HidePageLoader()
			else
				app.HidePageLoader()
				console.log "Es ist ein Fehler aufgetreten. Fehler-Code: " + overlayContentRequest.status
	offerDetailRequest.open "GET", window.location.protocol + "//" + window.location.host + "/api/offerdetail/" + offerId, true
	offerDetailRequest.send()
	return
app.AppendOfferDataToOverlayForm = ->
	overlay = document.getElementById('overlay')
	if overlay.querySelector '.offer-detail'
		fbElem = overlay.querySelector '.form-builder'
		if fbElem
			formContainer = fbElem.querySelector '.form-builder_content_form'

			offerIdInput = document.createElement 'input'
			offerIdInput.type = "hidden"
			offerIdInput.name = "offer-id"
			offerIdInput.value = overlay.querySelector('.offer-detail').dataset.offerId
			formContainer.append offerIdInput

			offerLocationInput = document.createElement 'input'
			offerLocationInput.type = "hidden"
			offerLocationInput.name = "offer-location"
			offerLocationInput.value = overlay.querySelector('.offer-detail_title_office').innerText.trim()
			formContainer.append offerLocationInput

			offerTitleInput = document.createElement 'input'
			offerTitleInput.type = "hidden"
			offerTitleInput.name = "offer-title"
			offerTitleInput.value = overlay.querySelector('.offer-detail_title_name .headline').innerText.trim()
			formContainer.append offerTitleInput

	return

# Page Loader
app.ShowPageLoader = ->
	document.getElementById('page-loader').classList.add 'shown'
	return
app.HidePageLoader = ->
	document.getElementById('page-loader').classList.remove 'shown'
	return

# Slider
app.SliderBinder = ->
	for sliderElem in document.querySelectorAll('.slider')
		sliderElem.dataset.activeSlide = "1"
		autoSlideTime = sliderElem.dataset.autoSlideTime

		id = app.SetId sliderElem, 'slider_'
		app.elems[id] = {
			animatedHintInterval: undefined,
			autoSlideActive: false,
			autoSlideInterval: undefined,
			hasAutoSlide: false
		}

		if sliderElem.querySelector('.slider-button')
			sliderElem.querySelector('.slider-button_prev').addEventListener 'click', (e) ->
				app.SliderSlide this.closest('.slider'), "prev"
				app.SliderStopAutoSlide this.closest('.slider').id
				app.SliderStopAnimatedHint this.closest('.slider').id
				return
			sliderElem.querySelector('.slider-button_next').addEventListener 'click', (e) ->
				app.SliderSlide this.closest('.slider'), "next"
				app.SliderStopAutoSlide this.closest('.slider').id
				app.SliderStopAnimatedHint this.closest('.slider').id
				return
		sliderElem.querySelector('.slider_content_slider_left').addEventListener 'click', (e) ->
			app.SliderSlide this.closest('.slider'), "prev"
			app.SliderStopAutoSlide this.closest('.slider').id
			app.SliderStopAnimatedHint this.closest('.slider').id
			return
		sliderElem.querySelector('.slider_content_slider_right').addEventListener 'click', (e) ->
			app.SliderSlide this.closest('.slider'), "next"
			app.SliderStopAutoSlide this.closest('.slider').id
			app.SliderStopAnimatedHint this.closest('.slider').id
			return
		sliderElem.querySelector('.slider_content_slider_touch').addEventListener 'touchstart', (e) ->
			this.closest('.slider').dataset.touchStart = e.touches[0].clientX
			return
		sliderElem.querySelector('.slider_content_slider_touch').addEventListener 'touchend', (e) ->
			if this.closest('.slider').dataset.touchStart && this.closest('.slider').dataset.touchStart != ""
				startX = parseInt this.closest('.slider').dataset.touchStart, 10
				this.closest('.slider').dataset.touchStart = ""
				endX = e.changedTouches[0].clientX
				diffX = startX - endX
				diffX = if diffX < 0 then diffX * (-1) else diffX
				if diffX > window.innerWidth * .1 # prevent too small touch moves or scroll touches
					if endX < startX
						app.SliderSlide this.closest('.slider'), "next"
					if endX > startX
						app.SliderSlide this.closest('.slider'), "prev"
					app.SliderStopAutoSlide this.closest('.slider').id
					app.SliderStopAnimatedHint this.closest('.slider').id
				return
		if autoSlideTime
			app.elems[id].hasAutoSlide = true
			app.SliderStartAutoSlide id
		if sliderElem.classList.contains 'slider--animated-hint'
			app.SliderStartAnimatedHint id
	return
app.SliderStartAutoSlide = (id) ->
	sliderElem = document.getElementById id
	app.elems[id].autoSlideActive = true
	window.clearInterval app.elems[id].autoSlideInterval
	app.elems[id].autoSlideInterval = window.setInterval ->
		app.SliderSlide sliderElem, "next"
		return
	, parseInt(sliderElem.dataset.autoSlideTime)
	return
app.SliderStopAutoSlide = (id) ->
	app.elems[id].autoSlideActive = false
	window.clearInterval app.elems[id].autoSlideInterval
	return
app.SliderStartAnimatedHint = (id) ->
	sliderElem = document.getElementById id
	animationTime = if sliderElem.dataset.autoSlideTime then parseInt(sliderElem.dataset.autoSlideTime,10)/2 else 1500
	app.SliderStopAnimatedHint id
	app.elems[id].animatedHintInterval = window.setInterval ->
		sliderElem.classList.toggle 'slider-button--hint'
		return
	, animationTime 
	return
app.SliderStopAnimatedHint = (id) ->
	document.getElementById(id).classList.remove 'slider-button--hint'
	window.clearInterval app.elems[id].animatedHintInterval
	return
app.SliderSlide = (sliderElem, direction, step) ->
	step = if step then step else 1
	activeSlide = parseInt sliderElem.dataset.activeSlide, 10
	slides = sliderElem.querySelectorAll('.slide')
	if direction == "next"
		if activeSlide + step > slides.length
			nextSlide = 1
		else
			nextSlide = activeSlide + step
	if direction == "prev"
		if activeSlide - step <= 0
			nextSlide = slides.length
		else
			nextSlide = activeSlide - step
	sliderElem.dataset.activeSlide = nextSlide
	translate = (slides[nextSlide-1].offsetLeft / sliderElem.querySelector('.slider_content_slider_track').offsetWidth) * 100
	sliderElem.querySelector('.slider_content_slider_track').style.transform = "translateX(-" + translate + "%)"
	return

# #########
# MAIN
# #########

app.Main = ->
	# ### COMMON
	app.AnchorLinkClickBinder()
	app.HashUrlBinder()
	app.OverlayOpenerBinder()
	app.WindowHistoryBinder()

	# ### ATOMS
	app.CheckboxBinder()
	app.InputBinder()
	app.ReadMoreButtonBinder()
	app.SelectBinder()

	# ### MOLECULES
	app.FoldableTileBinder()
	app.FormBuilderBinder()
	app.HeadlineCopyBinder()
	app.OfferInfoBoxBinder()
	app.ReadMoreWrapBinder()
	app.ScrollDownHintBinder()

	# ### ORGANISMS
	app.AnimatedHeroBinder()
	app.HeaderBinder()
	app.OffersBinder()
	app.OverlayBinder()
	app.SliderBinder()	

	return

app.Main()